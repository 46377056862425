//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProjectListTable from "@/components/Seo/ProjectListTable";
import DataTableActions from "@/components/DataTable/DataTableActions.vue";
import Indicator from "@/components/Loading/Indicator.vue";
// import ProductInfo from "@/components/Product/Info.vue";

export default {
  name: "ProductsQueriesReport",
  components: {
    ProjectListTable,
    DataTableActions,
    Indicator
  },
  emits: ["reverseDates"],
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    currentProject: {
      type: Object
    }
  },
  data() {
    return {
      queries: [],
      isLoaded: true,
      productsData: []
    };
  },
  async created() {
    await this.loadQueries();
    await this.loadProducts();
    // this.isLoaded = true;
  },

  methods: {
    async loadQueries() {
      const result = await this.$store.dispatch("seo/getMonitoringQueries", {
        ...this.reportSettings
      });
      this.queries = result.data;
    },
    async loadOneProduct(product) {
      return await this.$store.dispatch("product/getProduct", {
        p_id: product,
        mp: this.reportSettings.project.datasource,
        date: this.formatDate1,
        date2: this.formatDate2,
        fbs: 0
      });
    },
    async loadProducts() {
      this.productsData = [];
      let result = [];
      this.products.forEach(product => {
        result.push({
          p_id: product,
          loading: true
        });
      });
      this.productsData = result;
      for (const product of this.products) {
        let data = {};
        try {
          data = await this.loadOneProduct(product);
        } catch (e) {
          data = {
            sku: product,
            title: "&:NA",
            brand: "&:NA",
            seller: "&:NA"
          };
        }
        data.mp = this.reportSettings.project.datasource;
        data.loading = false;
        if (!data.mp_url && data.mp === "wb") {
          data.mp_url = `https://www.wildberries.ru/catalog/${data.sku}/detail.aspx?targetUrl=SP`;
        }
        if (!data.mp_url && data.mp === "ozon") {
          data.mp_url = `https://www.ozon.ru/product/${data.sku}`;
        }
        const index = this.productsData.findIndex(item => item.p_id === data.sku);
        this.productsData[index] = data;
        this.$forceUpdate();
      }
    }
  },
  computed: {
    products() {
      const products = [];
      this.queries.forEach(item => {
        if (!products.includes(item.p_id) && item.p_id) {
          products.push(item.p_id);
        }
      });
      return products;
    },
    formatDate1() {
      let split = this.reportSettings.date_from.split("-");
      return split[2] + "-" + split[0] + "-" + split[1];
    },
    formatDate2() {
      let split = this.reportSettings.date_to.split("-");
      return split[2] + "-" + split[0] + "-" + split[1];
    }
  },
  watch: {
    async currentProject() {
      this.isLoaded = false;
      await this.loadQueries();
      await this.loadProducts();
      this.isLoaded = true;
    }
  }
};